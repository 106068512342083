import React from 'react'
import './App.css'
import Sidebars from './components/Sidebar Section/Sidebars'
import Body from './components/Body Section/Body'


function App() {
  return (
    <div className='container'>
      <Sidebars />
      <Body />
    </div>
  )
}

export default App